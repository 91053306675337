import {T} from '@deity-io/falcon-i18n';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, {memo, useContext} from 'react';
import {useQuery} from 'react-apollo';
import {Link} from 'react-router-dom';
import clubCard from '../../../assets/img/emm-club-card.webp';
import clubCardRs from '../../../assets/img/klub_kartica_rs.webp';
import clubLogo from '../../../assets/img/emm-club-logo.webp';
import {useUserContext} from '../../../context/UserContext';
import {GET_CARD} from '../../PersonalArea/PersonalAreaClub/queriesClub';
import './ProductSidebarClub.scss';
import readConfig from '../../../utils/readClientConfiguration';
import productionConfiguration from '../../../../config/default.json';
import {isSI} from '../../../utils/isHr';
const countryCode = readConfig(productionConfiguration, 'countryCode');

// TODO: Figure out rounding error causing a difference of 1 point
function getBonusesForBanking(price, rate) {
    return Math.floor(price / rate) || 0;
}

export const FETCH_CONFIG = gql`
  query fetchLoyaltyConversion {
    backendConfig {
      shop {
        loyaltyConversion
      }
    }
  }
`;

const ProductSidebarClub = ({product}) => {
    let price;
    if (product.__typename === 'GroupedProduct') {
        price = product.items.reduce((sum, item) => {
            if (item.product && item.product.prices) {
                return sum + item.product.prices.reduce((itemSum, price) => {
                    return itemSum + (parseFloat(price.amount) || 0);
                }, 0);
            }
            return sum;
        }, 0);
    } else {
        const prices = product.prices.filter((price) => {
            return ['simple_clearance', 'simple_regular', 'simple_loyalty'].includes(price.price_type)
        }).map((price) => {
            return parseFloat(price.amount);
        });

        price = Math.min(...prices);
    }
    const {user} = useUserContext();
    const {data: card} = useQuery(GET_CARD, {
        fetchPolicy: 'no-cache',
        skip: !user,
    });

    const getBonusesForBanking = (price, rate) => {
        return Math.floor(price / rate) || 0;
    }

    const {data, loading} = useQuery(FETCH_CONFIG);

    const {loyaltyConversion: rate} = data && data.backendConfig && data.backendConfig.shop || {};
    const bankingBonus = Math.ceil(getBonusesForBanking(price, rate));


    if (loading) {
        return <></>;
    }
    return (
        <div className="sidebar-club">
            <div className="sidebar-club__header">
                <img src={clubLogo} alt=""/>
                {countryCode === 'hr-HR' && <img src={clubCard} alt="" className="sidebar-club__card"/>}
                {countryCode === 'sr-RS' && <img src={clubCardRs} alt="" className="sidebar-club__card"/>}
            </div>
            <div className="sidebar-club__bonuses">
                <div className="sidebar-club__bonus-text">
                    <T id="product.clubBonusHeader01"/>
                    <br/>
                    <T id="product.clubBonusHeader02"/>
                </div>
                {!isSI && <div className="sidebar-club__bonus-values">
                    {bankingBonus * 2} - <T id="product.bonusForEbanking"/>
                </div>
                }
                <div className="sidebar-club__bonus-values">
                    {bankingBonus} - <T id="product.bonusForCard"/>
                </div>
            </div>
            <div className="sidebar-club__info">
                {!user && (
                    <div className="sidebar-club__profile">
                        <T id="product.needRegister"/>
                        <br/>
                        <Link to="/customer/account/">
                            <T id="product.toProfile"/>
                        </Link>
                    </div>
                )}

                {card && card.historyCustomerLoyaltyCard && card.historyCustomerLoyaltyCard.loyalty ? (
                    <></>
                ) : (
                    <div className="sidebar-club__no-card">
                        <T id="product.noCard"/>
                        {user && (
                            <Link to="/customer/account/loyalty" className="sidebar-club__link">
                                <T id="product.askHere"/>
                            </Link>
                        )}
                        {!user && (
                            <Link to="/emmezeta-klub" className="sidebar-club__link">
                                <T id="product.askHere"/>
                            </Link>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

ProductSidebarClub.propTypes = {
    product: PropTypes.object,
};

export default memo(ProductSidebarClub);
